<template>
  <div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <template>
        <van-loading color="#fff" type="spinner"/>
      </template>
    </van-popup>
  </div>
</template>

<script>
export default {
  data () {
    return {
      show: true
    }
  }
}
</script>

<style scoped lang="less">
.van-popup {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 30px;
  border-radius: 16px;
}
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>

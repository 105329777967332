<template>
  <div class="privacy">
    <p class="text" @click="toAgreement">《Privacy Agreement》</p>
  </div>
</template>

<script>
export default {
  methods: {
    toAgreement () {
      this.$router.push({ name: 'Privacy' })
    }
  }
}
</script>

<style scoped lang="less">
.privacy {
  padding:50px 0;
}
.text {
  color: #BFC5CF;
  font-size: 12px;
}
</style>

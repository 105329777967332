import Vue from 'vue'

// 价格千分位
Vue.filter('formatAmount', function (amount) {
  if (!amount) return 0
  const str = typeof amount === 'string' ? amount : amount.toString()
  const arr = str.split('')
  const l = arr.length - 1
  let result = ''
  for (let i = l; i > -1; i--) {
    if ((l - i + 1) % 3 === 1 && i !== l) {
      result = arr[i] + ',' + result
    } else {
      result = arr[i] + result
    }
  }
  return result
})
// 处理时间格式
Vue.filter('formattedDate', function (time) {
  const times = Number(time)
  const date = new Date(times)
  const day = String(date.getDate()).padStart(2, '0') // 补零的日期
  const month = String(date.getMonth() + 1).padStart(2, '0') // 补零的月份
  const year = String(date.getFullYear()) // 年份

  const formattedDate = `${day}-${month}-${year}` // 格式化后的日期
  return formattedDate
})

import Axios from './axios.js'
import api from './api'
const getData = function (url, data, isQuery, method) {
  return Axios.request({
    url,
    data: isQuery ? undefined : data,
    params: isQuery ? data : undefined,
    method: method || 'post'
  })
}

export default { getData }

export const API = api

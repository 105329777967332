export default {
  orderDetail: '/creation/moral/lab', // 订单详情
  bankInfo: '/creation/person/presence', // 银行卡信息
  showAutoApply: '/creation/moral/psychologist', // 是否展示自动复借
  setAutoApply: '/creation/moral/tourist', // 是否自动复借
  repaymentDetails: '/creation/moral/powder', // 还款详情
  extensionOrderDetail: '/creation/require/interior', // 展期订单详情
  renewOrderUtr: '/creation/moral/cooperative', // 更新订单utr
  customerService: '/creation/adult/gather', // 获取客服信息
  complaint: '/creation/marketing/tower' // 用户反馈
}

import request from '../http/axios'
import Vue from 'vue'

function generalRecords (params, data) {
  request({
    url: `/creation/adult/apparent/${params.pageName}/${params.action}`,
    method: 'POST',
    data: {
      // extInfo
      rather: params.extInfo ? JSON.stringify(params.extInfo) : undefined,
      // userRandomKey
      mud: localStorage.getItem('userRandomKey'),
      // adid
      assume: localStorage.getItem('adid') || null
    }
  })
}
Vue.prototype.$generalRecords = generalRecords
export default {
  generalRecords
}

import axios from 'axios'
import { Toast } from 'vant'

const Axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 0
})

Axios.interceptors.request.use(config => {
  config.headers['x-auth-token'] = localStorage.getItem('token')
  config.headers['x-version'] = localStorage.getItem('version')
  config.headers['x-package-name'] = localStorage.getItem('packageName')

  return config
}, error => {
  return Promise.reject(error)
})

Axios.interceptors.response.use(response => {
  const code = response.data.arrive
  if (code === '-1') {
    Toast(response.data.tire)
  }
  if (code === '99') {
    Toast(response.data.tire)
  }
  if (code === '500') {
    Toast(response.data.tire)
  }
  return response.data
}, error => {
  return Promise.reject(error)
})
export default Axios

const dsBridge = require('dsbridge')

export default {
  install (Vue, options) {
    Vue.prototype.$javaTransfer = function (behavior, result) {
      dsBridge.call('android.handle', JSON.stringify([behavior]),
        function (res) {
          result(JSON.parse(res))
        })
    }
  }
}

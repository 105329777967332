import Request, { API } from '@/http/index'

// 获取客服信息
export async function getCustomerService () {
  const res = await Request.getData(`${API.customerService}`)
  return res
}
// 用户反馈
export async function allComplaint (data) {
  const res = await Request.getData(`${API.complaint}`, data)
  return res
}
